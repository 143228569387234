<template>
  <v-main>
    <v-layout row wrap class="mt-5">
      <BaseLabel :label="label" :vclass="labelclass" />
    </v-layout>
    <v-layout row wrap>
      <v-flex md12 xs12>
        <v-data-table
          :headers="HEADERS"
          :items="items"
          :items-per-page="rows"
          v-bind="$attrs"
        >
          <template v-slot:[`item.url`]="{ item }">
            <v-flex>
              <a
                :class="item.isShowURL ? 'disabledlink' : ''"
                @click="openUrl(item.url)"
                >{{ item.url }}</a
              >
            </v-flex>
          </template>
          <template v-slot:[`item.fileName`]="{ item }">
            <v-flex>
              <v-btn
                v-if="srcPage === 'accountchekSummary'"
                class="primary darken-2 text-none"
                @click="generatePDF(item.fileName)"
                >View
                <span>
                  {{ item.originalReport ? ' (Original Report)' : '' }}
                </span>
              </v-btn>
              <v-btn
                v-else
                class="primary darken-2"
                @click="generatePDF(item.fileName)"
                >View
              </v-btn>
            </v-flex>
          </template>

          <template v-slot:[`item.refresh`]="{ item }">
            {{ item.refresh }}
            <span v-if="item.remaining > 0"
              >({{ item.remaining }} days remaining)</span
            >
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-flex>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :disabled="item.isDisplayMail"
                    class="darken-2 ml-2"
                    @click="resendMail"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Resend Invite Email</span>
              </v-tooltip>
              <v-tooltip top v-if="item.isShowDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :disabled="item.isDisplayDelete"
                    class="darken-2 ml-2"
                    @click="deleteBorrower"
                    >mdi-delete-circle-outline</v-icon
                  >
                </template>
                <span>{{ item.label }}</span>
              </v-tooltip>
            </v-flex>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-main>
</template>

<script>
export default {
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    rows: {
      type: Number,
      default: 5
    },
    isHideFooter: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    labelclass: {
      type: String,
      default: ''
    },
    srcPage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      return() {}
    }
  },
  methods: {
    openUrl(frameURL) {
      window.open(frameURL, '_blank')
    },
    resendMail() {
      this.$emit('sendMail')
    },
    deleteBorrower() {
      this.$emit('deleteBorrower')
    },
    generatePDF(data) {
      this.$emit('generatePDF', data)
    }
  },
  computed: {
    HEADERS() {
      return this.fields.map((item) => {
        return Object.assign({}, { ...item }, { class: 'bheaders' })
      })
    }
  }
}
</script>

<style>
.bheaders {
  background: #f5f5f5;
  color: white;
  font-weight: bold;
}
.disabledlink {
  pointer-events: none;
}
</style>
