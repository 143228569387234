export const INTEGRATION_VOA = 'integration-voa'
export const INTEGRATION_FRAUDPLUS = 'integration-fraudplus'
export const SUMMARY_REQUEST = 'summary'
export const SUMMARY_REQUEST_INTERNAL = 'summary-internal'
export const PDF_VIEWER = 'PDF_VIEWER'

export const PRODUCT_VOA = 'voa'
export const PRODUCT_FRAUDPLUS = 'fraudplus'
export const CPSS_GET_VOA_ACCOUNTCHEK_SUMMARY =
  'CPSS_GET_VOA_ACCOUNTCHEK_SUMMARY'
export const CPSS_GET_VOA_REPORT = 'CPSS_GENERATE_VOA_REPORT'
export const CPSS_GET_CUST_USER_PRODUCTS = 'CPSS_GET_CUST_USER_PRODUCTS'
export const CPSS_GET_DOCUMENT_DOWNLOAD = 'CPSS_GET_DOCUMENT_DOWNLOAD'
export const CPSS_POST_SEND_MAIL = 'CPSS_SEND_MAIL'
export const INTEGRATION_REQUIRED_PRE_POST_CALL_FOR_VOA_IGNORE_RESPONSE =
  'RQD_VOA'
export const PRODUCT_FISERV = 'voaFiserv'
export const CPSS_GET_VOA_FISERV_SUMMARY = 'CPSS_GET_VOA_FISERV_SUMMARY'
export const CPSS_FISERV_POST_SEND_MAIL = 'CPSS_FISERV_POST_SEND_MAIL'
export const CPSS_GET_STATES = 'CPSS_GET_STATES'
export const CPSS_GET_VALIDATE_CARD = 'validateCard'
export const CPSS_FISERV_DELETE_USER = 'deleteUser'
export const CPSS_FISERV_GENERATE_REPORT = 'generateReport'
export const CPSS_FISERV_VIEW_REPORT = 'viewReport'
